import './index.scss'
import SupportButton from '../support-button'
import plus from './img/plus.svg'

const Faq = () => {

  const handleSwitch = (e) => {
    const faqItem = e.target.closest('.faq__item')
    if (faqItem.classList.contains('active')) {
      faqItem.classList.remove('active')
    } else {
      faqItem.classList.add('active')
    }
  }

  return (
    <div className="faq" id="faq">
      <div className="container">
        <h2 className="faq__title">Часті запитання</h2>
        <div className="faq__content">
          <div className="faq__left">
            <div className="faq__left-icon">
              👋🏼
            </div>
            <h3>Залишились запитання?</h3>
            <p>Звертайтеся до служби технічної підтримки Dubidoc через чат підтримки або електронну пошту.</p>
            <SupportButton />
          </div>
          <div className="faq__right">
            <div className="faq__items">
              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>01.</span> Чим відрізняється шеринг на email і публічний шеринг?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Різниця полягає у правах доступу до Вашого документа.
                  </p>
                  <ul>
                    <li>
                      Шеринг на email: доступ до документа мають тільки ті користувачі, яких Ви вказали. Користувачі
                      отримають сповіщення на пошту про отриманий документ та інструкції щодо необхідної дії
                      (підписання/перегляд).
                    </li>
                    <li>
                      Публічний шеринг: доступ до документа мають всі, у кого є посилання з заданою дією
                      (перегляд/підписання).
                    </li>
                  </ul>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>02.</span> Як додати організацію?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Організація створюється автоматично при першому підписанні документа.
                  </p>
                  <p>
                    Якщо у Вас більше однієї організації, додайте нову організацію в розділі «Мої організації» за
                    допомогою цифрового підпису. Після цього у Вас відкриється функціонал мультиорганізацій.
                  </p>
                  <p>
                    Ви зможете переключатися між організаціями, натиснувши на іконку людини у правому верхньому куті.
                  </p>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>03.</span> Скільки підписів можна накласти на документ?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Кількість підписів, котрі Ви можете накласти на документ, є необмеженою.
                  </p>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>04.</span> Чи потрібно моєму контрагенту бути зареєстрованим у сервісі, щоб підписати
                    документ?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Ні, Ваш контрагент може підписати документ без реєстрації. Просто надішліть документ на його email
                    або відправте йому публічне посилання, і він зможе підписати його.
                  </p>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>05.</span> Як перевірити коректність підпису завантажених документів?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <ol>
                    <li>
                      Завантажте документи із сервісу на комп'ютер.
                    </li>
                    <li>
                      Збережіть файли з архіву на комп'ютері та перейдіть на сторінку державного онлайн сервісу
                      перевірки КЕП/ЕЦП ‒ <a
                      href="https://czo.gov.ua/verify"> https://czo.gov.ua/verify</a>
                    </li>
                    <li>
                      На сторінці, що відкрилася, натисніть кнопку "Завантажте зі свого носія". У новому вікні оберіть
                      оригінал документу (розширення .pdf, .xml тощо) з архіву та один файл електронного підпису з
                      розширенням .p7s одночасно.
                    </li>
                    <li>
                      Після вибору файлу документу та одного підпису натисніть кнопку "Перевірити".
                    </li>
                    <li>
                      Після перевірки накладеного підпису і самого оригіналу документу, на екран виводиться інформація з
                      результатом.
                    </li>
                    <li>
                      Повторіть крок 3,4,5 з іншими підписами (за наявності).
                    </li>
                  </ol>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>06.</span> Як ми захищаємо дані?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Захист даних здійснюється за допомогою авторизації – без токена доступу приватні документи недоступні.
                  </p>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>07.</span> Де зберігаються дані/документи?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Ми використовуємо хмарне сховище Amazon S3, яке забезпечує надійність збереження даних, в тому числі їхнє відновлення у випадку несправностей на стороні Amazon.
                  </p>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>08.</span> Як довго зберігаються документи?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    На даний момент документи зберігаються необмежену кількість часу.
                  </p>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>09.</span> Як налаштувати маршрут з використанням API?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Після релізу Dubidoc ми опублікуємо АРІ документацію з детальним описом усіх методів, які можна застосовувати для зручної взаємодії із нашим сервісом.
                  </p>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>10.</span> Які формати підписів / контейнерів використовуєте?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Наразі ми використовуємо зовнішні підписи документів: СAdES-B-LT (ETSI EN 319 122-1)
                  </p>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>11.</span> Що таке Dubidoc?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Dubidoc – це перший в Україні безкоштовний сервіс з застосунками на IOS та Android, в якому можна підписати документ онлайн за допомогою Дія, КЕП(ЕЦП) або SmartID та надіслати підписаний документ в улюблених месенджерах. Відтепер все швидко та зручно, адже ми спростили електронний документообіг до кількох кліків! Це дозволить вам:
                  </p>
                  <ul>
                    <li>Завантажити будь-який документ у різних форматах (PDF, Word, Excel, JPEG, PNG). Акт, договір, рахунок та багато іншого.</li>
                    <li>Підписати документи в Дія, надаючи їм юридичної сили.</li>
                    <li>Надіслати підписані документи колегам та клієнтам в месенджері (Telegram, WhatsApp, Viber) або на електронну пошту, здійснюючи електронний документообіг між контрагентами.</li>
                  </ul>
                </div>
              </div>

              <div className="faq__item">
                <div className="faq__item-header" onClick={handleSwitch}>
                  <div>
                    <span>12.</span> Скільки це буде коштувати?
                  </div>
                  <img src={plus} alt="" className="faq__icon-plus" />
                </div>
                <div className="faq__item-content">
                  <p>
                    Користування сервісом буде безоплатним ще протягом року, без жодних платежів та прихованих комісій. По закінченню цього терміну ми сповістимо вас та розмістимо всю інформацію щодо майбутніх цін та платних тарифів на сайті Dubidoc.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq