import './index.scss'

const Reviews = () => {
  return (
    <div className="reviews" id="reviews">
      <div className="container">
        <h2 className="reviews__title">Відгуки</h2>
        <div className="reviews__reviews">
          <div className="reviews__review-item">
            <div className="reviews__review-item-message">
              😍😍😍
            </div>
            <h4 className="reviews__review-item-title">Андрій</h4>
          </div>
          <div className="reviews__review-item">
            <div className="reviews__review-item-message">
              🌳🦫❤️
            </div>
            <h4 className="reviews__review-item-title">Юлія</h4>
          </div>
          <div className="reviews__review-item">
            <div className="reviews__review-item-message">
              💸⏱🔥
            </div>
            <h4 className="reviews__review-item-title">Дмитро</h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reviews