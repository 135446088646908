import './index.scss'
import {
  useEffect,
  useState,
  useRef
} from 'react'
import Slider from 'react-slick'

const AdvantagesItem = ({ item }) => {
  return (
    <div className="advantages__item">
      <div className="advantages__item-icon">
        <img
          src={require(`./img/${item.icon}.png`)}
          srcSet={`${require(`./img/${item.icon}.png`)} 1x, ${require(`./img/${item.icon}@2x.png`)} 2x`}
          alt="Icon"
        />
      </div>
      <p>{item.text}</p>
    </div>
  )
}

const Advantages = () => {
  let sliderRef = useRef(null)
  const settings = {
    className: 'slider variable-width',
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: false
  }
  const [mdDevice, setMdDevice] = useState(
    window.matchMedia('(max-width: 960px)').matches
  )

  useEffect(() => {
    window
      .matchMedia('(max-width: 960px)')
      .addEventListener('change', e => setMdDevice(e.matches))
  }, [])

  const items = [
    {
      icon: 'case',
      text: 'Ідеально як для особистого користування, так і для підприємців'
    },
    {
      icon: 'notebook',
      text: 'Дія.Підпис має таку ж юридичну силу, як і підпис на папері'
    },
    {
      icon: 'paper-plane',
      text: 'Документи можна зашерити як для підпису, так і для перегляду'
    },
    {
      icon: 'phone',
      text: 'Зручне керування доступами  до акаунту та документу'
    },
    {
      icon: 'lock',
      text: 'Зручне та надійне зберігання документів'
    },
    {
      icon: 'code',
      text: 'Безпека та захист приватності'
    }
  ]
  return (
    <div className="advantages" id="advantages">
      <div className="container">
        <h2 className="reviews__title">Переваги</h2>
        {mdDevice ? (
          <Slider
            ref={slider => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {items.map((item, key) => {
              return (
                <AdvantagesItem
                  item={item}
                  key={key}
                />
              )
            })}
          </Slider>
        ) : (
          <div className="advantages__items">
            {items.map((item, key) => {
              return (
                <AdvantagesItem
                  item={item}
                  key={key}
                />
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default Advantages