import '../public-offer/index.scss'

const PrivacyPolicyContent = () => {
	return (
		<div className="public-offer">
			<div className="container">
				<h1>Політика конфіденційності</h1>
				<ol type="I">
					<li>
						<span className="public-offer__list-item-title">Загальні положення</span>
						<ol>
							<li>
								Web-сайт <a target="_blank"
								            href="https://my.dubidoc.com.ua/">https://my.dubidoc.com.ua/</a> (
								далі – Сайт) належить на праві власності ТОВ “ЧЕКБОКС”, код ЄДРПОУ 43721744 (далі – Компанія).
								Всі об’єкти права інтелектуальної власності, які містяться на сайті, окрім випадків, коли про це окремо
								зазначено, належать на праві власності Компанії.
							</li>
							<li>
								Дана Політика конфіденційності та захисту персональних
								даних (далі – Політика конфіденційності) є чинною виключно в
								рамках Правил користування Сайтом та не поширюється на web – сайти третіх осіб.
							</li>
							<li>
								Дана Політика Політики конфіденційності є комплексом правових, адміністративних,
								організаційних, технічних та інших заходів, які вживає Компанія з метою
								забезпечення конфіденційності та захисту персональних даних фізичних осіб – споживачів,
								які є користувачами Сайту, окрім випадків, коли така інформація
								особисто та свідомо розголошується споживачем – користувачем Сайту.
							</li>
							<li>
								Метою впровадження Політики конфіденційності та захисту персональних даних є
								недопущення публічного доступу та розголошення інформації, володільцем якої є споживач.
							</li>
							<li>
								Компанія вживає всіх можливих заходів з метою забезпечення виконання заходів,
								передбачених даним розділом Політики конфіденційності.
							</li>
						</ol>
					</li>
					<li>
						<span className="public-offer__list-item-title">Доступ до персональних даних</span>
						<ol>
							<li>
								З метою реалізації функцій та завдань Сайту та інших цілей, пов’язаних з цивільно-правовими
								відносинами між Компанією та споживачем, споживач, користуючись даним Сайтом та послугами,
								для надання яких створений Сайт, надає Компанії дозвіл на збирання, накопичення, обробку,
								зберігання та використання (в тому числі з використанням автоматизованих засобів)
								персональних даних, володільцем яких він являється.
								<ol>
									<li>
										З метою реалізації функцій та завдань Сайту та інших цілей,
										пов’язаних з цивільно- правовими відносинами між Компанією та споживачем,
										споживач, користуючись даним Сайтом та послугами, для надання яких створений
										Сайт, надає Компанії дозвіл на передачу власних персональних даних третім особам.
									</li>
								</ol>
							</li>
							<li>
								Згода споживача, яка надається Компанії на збирання, обробку,
								зберігання та використання (в тому числі з використанням автоматизованих засобів)
								персональних даних та їх передачу третім особам оформлюється шляхом проставлення
								споживачем відмітки про надання дозволу на збирання, накопичення, обробку,
								зберігання та використання своїх персональних даних.
							</li>
							<li>
								До персональних даних, на збирання, накопичення, обробку,
								зберігання та використання яких (в тому числі з використанням автоматизованих засобів)
								споживач надає дозвіл Компанії входить:
								<ol type="a">
									<li>прізвище, ім’я, по-батькові;</li>
									<li>номери засобів зв’язку (телефон, e-mail);</li>
									<li>сфера діяльності;</li>
									<li>Інша інформація, яка самостійно вноситься споживачем.</li>
								</ol>
							</li>
							<li>
								При оплаті замовлення банківською картою обробка платежу
								(включаючи введення номера картки) відбувається на захищеній сторінці процесингової системи.
								Це означає, що Ваші конфіденційні дані (реквізити картки, реєстраційні дані та ін.)
								не надходять до нас, їх обробка повністю захищена і ніхто не може отримати персональні
								та банківські дані клієнта.
							</li>
							<li>
								Під час роботи з картковими даними застосовується стандарт
								захисту інформації, розроблений міжнародними платіжними системами
								Visa та MasterCard – Payment Card Industry Data Security Standard (PCI DSS),
								що забезпечує безпечну обробку реквізитів Банківської картки Держателя.
								Технологія передачі даних, що застосовується, гарантує безпеку по операціях
								з Банківськими картами шляхом використання протоколів Secure Sockets Layer (SSL).
							</li>
						</ol>
					</li>
					<li>
						<span className="public-offer__list-item-title">Строк та місце зберігання Персональних даних</span>
						<ol>
							<li>
								Персональні дані, на збирання, накопичення, обробку, зберігання
								та використання яких споживач надає доступ Компанії зберігаються безстроково,
								якщо інше не встановлено законодавством України або волевиявленням споживача.
							</li>
							<li>
								Місцезнаходженням Компанії є – 01001, м.Київ, ВУЛИЦЯ МИХАЙЛА ГРУШЕВСЬКОГО, будинок 10, офіс 212.
							</li>
						</ol>
					</li>
					<li>
						<span className="public-offer__list-item-title">Права споживача</span>
						<ol>
							<li>
								Споживач, відносно власних персональних даних, які надаються для збирання,
								накопичення, обробку, зберігання та використання Компанії має право:
								<ol>
									<li>
										Знати про джерела збирання, місцезнаходження своїх персональних даних, мету їх обробки,
										місцезнаходження Компанії;
									</li>
									<li>
										Отримувати інформацію про умови надання доступу до персональних даних;
									</li>
									<li>
										На доступ до своїх персональних даних;
									</li>
									<li>
										Отримувати інформацію стосовного того, чи обробляються його персональні дані;
									</li>
									<li>
										пред’являти вмотивовану вимогу Компанії із запереченням проти обробки своїх персональних даних;
									</li>
									<li>
										пред’являти вмотивовану вимогу щодо зміни або знищення своїх персональних даних, якщо ці дані обробляються незаконно чи є недостовірними;
									</li>
									<li>
										на захист своїх персональних даних від незаконної обробки та випадкової втрати, знищення, пошкодження у зв’язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також на захист від надання відомостей, що є недостовірними чи ганьблять честь, гідність та ділову репутацію фізичної особи;
									</li>
									<li>
										звертатися із скаргами на обробку своїх персональних даних до Уповноваженого Верховної Ради України з прав людини або до суду;
									</li>
									<li>застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних;</li>
									<li>вносити застереження стосовно обмеження права на обробку своїх персональних даних під час надання згоди;</li>
									<li> відкликати згоду на обробку персональних даних;</li>
									<li>знати механізм автоматичної обробки персональних даних;</li>
									<li>на захист від автоматизованого рішення, яке має для нього правові наслідки.</li>
								</ol>
							</li>
						</ol>
					</li>
					<li>
						<span className="public-offer__list-item-title">Розкриття конфіденційної інформації та Персональних даних</span>
						<ol>
							<li>Компанія звільняється від обов’язку дотримання Політики конфіденційності стосовно Персональних даних споживача у випадку, якщо споживач самостійно публічно розголошує персональні дані.</li>
							<li>Компанія має право поширювати персональні дані споживача у випадку якщо інформація, отримана зі складу персональних даний є суспільно необхідною, тобто є предметом суспільного інтересу, і право громадськості знати таку інформацію переважає потенційну шкоду від її поширення.</li>
							<li>Компанія має право поширювати персональні дані споживача з відповідного письмового дозволу споживача.</li>
							<li>Компанія не несе відповідальності за використання Користувачем стороннього ПЗ та бібліотек, за шкоду або збитки, яких зазнав Користувач або треті особи в результаті невірного розуміння або нерозуміння умов цієї Політики конфіденційності, інструкцій або вказівок про порядок використання Сервісу, порядок розміщення даних та інших технічних питань.</li>
						</ol>
					</li>
					<li>
						<span className="public-offer__list-item-title">Знищення та видалення персональних даних</span>
						<ol>
							<li>
								Персональні дані, на збирання, накопичення, обробку, зберігання та використання яких споживач надає доступ Компанії підлягають видаленню або знищенню у разі:
								<ol>
									<li>припинення правовідносин між споживачем та Компанією;</li>
									<li>видання відповідного припису Уповноваженого Верховної ради з прав людини або визначених ним посадових осіб секретаріату Уповноваженого Верховної ради з прав людини;</li>
									<li>набрання законної сили рішенням суду щодо видалення або знищення персональних даних.</li>
								</ol>
							</li>
						</ol>
					</li>
				</ol>
			</div>
		</div>
	)
}

export default PrivacyPolicyContent