import { useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import PrivacyPolicyContent from "../components/privacy-policy";

const PrivacyPolicy = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
			<div className="App">
				<Header />
				<main>
					<PrivacyPolicyContent />
				</main>
				<Footer />
			</div>
	)
}

export default PrivacyPolicy
