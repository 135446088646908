import './index.scss'
import appStore from './img/app-store.svg'
import playMarket from './img/play-market.svg'

const Mobile = () => {
  return (
    <footer className="mobile" id="mobile">
      <div className="container">
        <div className="mobile__content">
          <div className="mobile__left">
            <h3 className="mobile__title">
              Швидкий підпис та обмін документів у смартфоні
            </h3>
            <p className="mobile__text">
              Підписуйте документи в Android та iOS застосунку і надсилайте через ваш улюблений месенджер або email. Просте і зручне рішення, яке завжди під рукою
            </p>
            <div className="mobile__apps">
              <a href="https://apps.apple.com/ua/app/dubidoc/id6501991971?utm_source=landing&utm_medium=button" target="_blank">
                <img src={appStore} alt="AppStore" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=ua.checkbox.edo&utm_source=landing&utm_medium=button" target="_blank">
                <img src={playMarket} alt="PlayMarket" />
              </a>
            </div>
          </div>
          <div className="mobile__right">
            <div>
              <img
                src={require('./img/screen.png')}
                srcSet={`${require('./img/screen@2x.png')} 2x, ${require('./img/screen@3x.png')} 3x`}
                alt="Інтерфейс мобільного застосунку Дубідок"
                className="mobile__screen"
              />
              <img
                src={require('./img/1.png')}
                srcSet={`${require('./img/1@2x.png')} 2x, ${require('./img/1@3x.png')} 3x`}
                alt="Screen"
                className="mobile__msg-1"
              />
              <img
                src={require('./img/2.png')}
                srcSet={`${require('./img/2@2x.png')} 2x, ${require('./img/2@3x.png')} 3x`}
                alt="Screen"
                className="mobile__msg-2"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Mobile