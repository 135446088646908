import './index.scss'
import logo from '../../img/logo.svg'
import SupportButton from '../support-button'
import { HashLink as Link } from 'react-router-hash-link'

const Header = ({ buttonHref }) => {
  return (
    <header className="header" id="header">
      <div className="container">
        <div className="header__content">
          <Link to="/">
            <img
              className="header__logo"
              alt="Logo"
              src={logo}
            />
          </Link>
          <div className="header__btns">
            <SupportButton />
            <a href={buttonHref} target="_blank" className="button-main" id="vhid">
              Вхід
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header