import './index.scss'
import play from './img/play.svg'
import { useRef } from 'react'

const Video = () => {
  const videoRef = useRef(null)
  return (
    <div className="video" id="video">
      <div className="container">
        <div className="video__content">
          {/*<img*/}
          {/*  className="video__video"*/}
          {/*  alt="video"*/}
          {/*  src={require('./img/video.png')}*/}
          {/*/>*/}
          <video
            ref={videoRef}
            className="video__video"
            src={require('./img/video.mp4')}
            controls={true}
            muted={true}
            loop={true}
            playsInline={true}
            autoPlay={true}
          />
          {/*<div className="video__play-btn">*/}
          {/*  <img src={play} alt="Play" />*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  )
}

export default Video