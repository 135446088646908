import './index.scss'
import { useState } from 'react'
import contact from '../contact'

const Description = () => {
  const [selected, setSelected] = useState(1)
  const lists = {
    1: [
      { text: 'Договори', icon: 'contract' },
      { text: 'Акти', icon: 'act' },
      { text: 'Рахунки на оплату', icon: 'receipt' },
      { text: 'Звіти', icon: 'report' },
      { text: 'Протоколи зборів', icon: 'protocol' },
      { text: 'Угоди на обслуговування', icon: 'agreement' },
    ],
    2: [
      { text: 'Накази', icon: 'order' },
      { text: 'Статути', icon: 'contract' },
      { text: 'Заяви', icon: 'act' },
      { text: 'Положення про структурні підрозділи', icon: 'protocol' },
      { text: 'Інструкції', icon: 'instruction' },
      { text: 'Службові записки', icon: 'agreement' },
    ]
  }

  const handleSwitch = (value) => {
    setSelected(value)
  }

  return (
    <footer className="description" id="description">
      <div className="container">
        <div className="description__content">
          <div className="description__left">
            <h3 className="description__title">
              Для кого
            </h3>
            <p className="description__text">
              Завдяки своїй простоті Dubidoc підходить як для особистих справ, так і для бізнесу, який масово підписує типові документи зі своїми клієнтами (контрагентами) або співробітниками. Від сервісних компаній, провайдерів до ОСББ та навчальних закладів
            </p>
          </div>
          <div className="description__right">
            <div className="description__switcher">
              <div
                onClick={() => handleSwitch(1)}
                className={selected === 1 ? 'active' : ''}
              >
                Зовнішні {selected === 1 ? 'документи' : ''}
              </div>
              <div
                onClick={() => handleSwitch(2)}
                className={selected === 2 ? 'active' : ''}
              >
                Внутрішні {selected === 2 ? 'документи' : ''}
              </div>
            </div>

            <div className="description__list">
              {lists[selected].map((item, index) => (
                <div key={index} className="description__item">
                  <div className="description__item-icon">
                    <img
                      src={require(`./img/${item.icon}.png`)}
                      srcSet={`${require(`./img/${item.icon}@2x.png`)} 2x, ${require(`./img/${item.icon}@3x.png`)} 3x`}
                    />
                  </div>
                  <div className="description__item-text">
                    {item.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Description