import './index.scss'

const Web = ({ buttonHref }) => {
  return (
    <footer className="web" id="web">
      <div className="container">
        <div className="web__content">
          <div className="web__left">
            <img
              src={require('./img/screen.png')}
              srcSet={`${require('./img/screen@2x.png')} 2x, ${require('./img/screen@3x.png')} 3x`}
              alt="Iнтерфейс веб-версії сервісу Дубідок"
              className="web__screen"
            />
            <img
              src={require('./img/1.png')}
              srcSet={`${require('./img/1@2x.png')} 2x, ${require('./img/1@3x.png')} 3x`}
              alt="Screen"
              className="web__msg-1"
            />
            <img
              src={require('./img/2.png')}
              srcSet={`${require('./img/2@2x.png')} 2x, ${require('./img/2@3x.png')} 3x`}
              alt="Screen"
              className="web__msg-2"
            />
          </div>
          <div className="web__right">
            <h3 className="web__title">
              Максимальна <br /> зручність веб-версії
            </h3>
            <p className="web__text">
            Простий та мінімалістичний сервіс у вікні вашого браузера. Завантажуйте, підписуйте і діліться документами
              всього у декілька кліків
            </p>
            <a href={buttonHref} target="_blank" className="button-main" id="try-secondscreen">
              Спробувати
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Web