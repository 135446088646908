import { useEffect } from 'react'
import Header from '../components/header'
import Footer from '../components/footer'
import PublicOfferContent from '../components/public-offer'

const PublicOffer = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="App">
      <Header />
      <main>
        <PublicOfferContent />
      </main>
      <Footer />
    </div>
  )
}

export default PublicOffer
