import './index.scss'
import facebook from '../../img/facebook.svg'
import instagram from '../../img/instagram.svg'
import linkedin from '../../img/linkedin.svg'
import telegram from '../../img/telegram.svg'
import youtube from '../../img/youtube.svg'

const socials = [
	{
		logo: facebook,
		href: 'https://www.facebook.com/profile.php?id=61561763253397'
	},
	{
		logo: instagram,
		href: 'https://www.instagram.com/dubidoc.ua/'
	},
	{
		logo: linkedin,
		href: 'https://www.linkedin.com/company/dubidoc-ua/about/'
	},
	{
		logo: youtube,
		href: 'https://www.youtube.com/@Dubidoc'
	},
	{
		logo: telegram,
		href: 'https://t.me/dubidoc'
	}
]

const Socials = () => {
	return (
		<div className="socials">
			{socials.map((social, idx) => (
					<a href={social.href} className="socials__button" target="_blank" key={idx}>
						<img src={social.logo} alt="social-logo"/>
					</a>
			))}
		</div>
	)
}

export default Socials